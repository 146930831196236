@media only screen and (max-width: 979px) {
 .loginPage {
    grid-template-columns: 1fr;
    min-height: 100vh;

    &__left {
        display: none;
    }
    &__right {
        padding: 1rem;
        width: 100%;

        form {
            width: 100%;
        }
    }
 }
 .SignInPage {
    grid-template-columns: 1fr;
    &__left {
        display: none;
    }
    &__right {
        &Box {
            grid-template-columns: 1fr;
        }
    }
}
.dashboard {
    grid-template-columns: 1fr;
    &__left {
        position: fixed;
      left: -300px;
      top: 0;
      width: 300px;
      transition: .5s;
        &Nav {
          position: absolute;
          color: #fff;
          background: #fff;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: grid;
          place-items: center;
          right: -2.3rem;
          top: 7.6rem;
          cursor: pointer;
      
          i,svg {
            color: #000;
            transition: .5s;
          }
        
         
        }
        &.active {
            left: 0;
            .dashboard__leftNav {
                transform: rotate(180deg);
              }
          }
      }
      &__right {
        width: 100%;
      }
}

.dashboard2d {
    grid-template-columns: 1fr 1fr;
}
.dashboard__Body-top- {
    flex-direction: column;
    gap: 1rem;
    &-r {
        &>div {
            flex-direction: column;
            gap: 0.3rem;
        }
    }
}
.AnalyticsPage {
    &_top {
        grid-template-columns: 1fr;
    }
    .cardRevenue {
        &_top {
            flex-direction: column;
        }
    }
}
.card {
    &_top {
        &2 {
            flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
        }
    }
}

.orderPage_topl {
    &> ul {
        flex-wrap: wrap;
        gap: 0 0.4rem;
    }
}
.ant-modal-body .grid-cols-2 {
    grid-template-columns: 1fr;
}
.dashboard__Body-top-rres {
    flex-direction: column;
    gap: 0.5rem;
}
.anticon svg {
    width: 0.8rem;
}









// --------------------------------vender------------------
.dashboard2grid {
    grid-template-columns: 1fr;
}
.productRes {
    padding: 0;
}
.reportss {
    grid-template-columns: 1fr;
    .reportssCard_head div {
        font-size: 11px;
    }
    .reportssCard_bodyBox {
        gap: .4rem;
    }
    .reportssCard_bodyBox2 {
        font-size: 13px;
        padding: 0.2rem 0.2rem;
    }
}

.dashboard__leftbottom {
    bottom: 6rem;
}

.settingBox_input {
    width: 100%;
}
.tablereactSlider22 {
width: 100%;
}
.ant-modal {
    width: 90% !important;
}
.tablereactSlider img {
    height: 100%;
    width: 100%;
}

.Discussions_ {
    grid-template-columns: 1fr;
    &left {
    }
    &right {
    }
}
  }


  @media only screen and (max-width: 460px) {
    .dashboard2d {
        grid-template-columns: 1fr;
    }
    .card_top2-r {
        flex-direction: column;
        width: 100%;
        .btn-primary {
            width: 100%;
            justify-content: center;
        }
        .vendertotal {
            width: 100%;
            text-align: center;
        }
    }
    .tableaction {
        gap: 0.3rem;
        width: 3rem;
        flex-wrap: wrap;
        justify-content: center;
    }

    .dashboard__Body {
        padding: 4rem 0.5rem;
    }
    .dashboard__Body-top {
        padding: .5rem;
        text-align: center;
    }
    select.form-control {
        width: 6rem;
    }
    .emaillist {
        width: 8rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
    }

    .puplo {
        flex-direction: column;
        gap: 0.1rem;
    }
    
    .ant-upload.ant-upload-select {
        width: 60px !important;
        height: 60px !important;
    }
    
    .ant-upload-list-item-container {
        width: 60px !important;
        height: 60px !important;
    }
    
    .SignInPage__right {
        padding: 4rem 1rem;
    }
  }