@import "./reset";
@import "./variable";
@import "./function";
@import "./mixin";
@import "./typography";
@import "./layout";
@import "./list";
@import "./btn";
@import "./banner";
@import "./media";
@import "./input";
@import "./search";
@import "./animation";
@import "./login";

.dashboard {
  grid-template-columns: 2fr 8fr;

  &__left {
    position: relative;
    border-right: 0.1rem solid #ddd;
    width: 22%;
    height: 100vh;
    z-index: 3;
    background: #000000;
    &logo {
      padding: 2rem 0;
      img {
        width: 3rem;
      }
      span {
        color: #fff;
        font-weight: 600;
        font-size: 1.4rem;
      }
    }
    &bottom {
      position: absolute;
      left: 0;
      bottom: 2rem;
      width: 100%;

      a {
        padding: 0.8rem 2rem;
        font-size: 1rem;
        transition: 0.3s;
        border-radius: 0.2rem;
        color: #fff;
        svg {
          top: -0.1rem;
        }
        &:hover {
          color: $primary-color;
          background-color: #f5f5f5;
        }
      }
    }
  }
  &__right {
    width: 78%;
  }
  &__Body {
    position: relative;
    padding: 2rem;
    background: #e8effc;
    height: 100vh;
    overflow: auto;
    .dashboard2d {
      &Box {
        background: #fff;
        border-radius: 1rem;
        grid-template-columns: auto 1fr;
        &__left {
          .h3 {
            color: #a3aed0;
            font-size: 15px;
            margin-bottom: 0.3rem;
            line-height: 1;
          }
          .h4 {
            font-size: 23px;
            font-weight: 700;
            line-height: 1;
          }
          &B {
            grid-template-columns: auto 1fr;
            &img {
              img {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
        &__right {
          margin-left: auto;
        }
        &.bb1 {
          .dashboard2dBox__right {
            img {
              max-width: 3rem;
              min-width: 3rem;
            }
          }
        }
        &.bb2 {
          .dashboard2dBox__right {
            position: relative;
            img {
              position: absolute;
              min-width: 4rem;
              right: 0;
              display: block;
            }
          }
        }
        &.bb3 {
          .dashboard2dBox__left {
            .h4 {
              color: $primary-color;
            }
          }
          .dashboard2dBox__right {
            position: relative;
            img {
              width: 50px;
            }
          }
        }
        &.bb4 {
          background: $primary-color;
          color: #fff;
          .h3 {
            color: #fff;
          }
        }
      }
    }
    &-top {
      .h6 {
      }
      &-l {
        font-size: 34px;
        line-height: 1;
      }
      &-r {
        position: relative;
        input {
          padding: 1rem 2rem;
          outline: none;
          border: 0.1rem solid #ddd;
          border-radius: 0.4rem;
          padding-left: 3.1rem;
        }
        .btn {
          img {
            width: 30px;
          }
        }
        &r {
          img {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            width: 1.4rem;
          }
        }
      }
    }
  }

  &2 {
    &C {
      &ard {
        background: #fff;
        border-radius: 1rem;
        padding: 1rem 2rem;

        h3 {
          font-size: 1.4rem;
          margin: 0;
        }

        &B {
          &ox {
            padding: 1rem;
            & + & {
              border-left: 2px solid #f0f1f3;
            }
            &img {
              img {
                width: 3rem;
              }
            }
            &body {
              color: $secondary-color;
              .h4 {
                font-weight: 600;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

.card {
  background: #fff;
  border-radius: 1rem;
  &__top {
    padding: 1rem 1.2rem 0;
    .h2 {
      margin: 0;
      line-height: 1;
      font-weight: 500;
      color: #a3aed0;
    }
    .h6 {
      font-size: 17px;
      font-weight: 600;
    }
    &-right {
    }
    select {
      border: 1px solid #ddd;
      padding: 1rem 1rem;
      outline: none;
      border-radius: 1rem;
    }
  }
  .card_top2 {
    &-l {
      h4 {
        font-size: 1.2rem;
      }
    }
    .btn-primary {
      padding: 0.5rem 1.4rem;
      img {
        width: 1.6rem;
      }
    }
  }
}
.card2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &Box {
    text-align: center;
    &Img {
      img {
        width: 10rem;
        border-radius: 3rem;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
    }
  }
  &Box2 {
    display: flex;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    margin-top: 2rem;

    span {
      color: #a3aed0;
    }
    p {
      font-size: 35px;
      color: #1b2559;
      font-weight: 600;
    }
  }
}
.dashboard2grid {
  grid-template-columns: 6fr 4fr;
}
.Inventorycard {
  border-radius: 1rem;
  h3 {
    font-size: 1.3rem;
    font-weight: 600;
  }
  &B {
    &ox {
      & + & {
        border-left: 1px solid #ddd;
      }
      h4 {
        font-size: 1rem;
      }
      .i1 {
        color: #f85032;
      }
      .i2 {
        color: #e19133;
      }
      .i3 {
        color: #845ebc;
      }
      .i4 {
        color: #f36960;
      }
      &_ {
        span {
          color: #5d6679;
          font-weight: 600;
          margin-top: 0.4rem;
        }
        p {
          color: #858d9d;
          margin: 0;
          margin-top: 0.4rem;
        }
        &left {
        }
        &right {
          text-align: right;
        }
      }
    }
  }
}

.orderPage {
  &_top {
    &l {
      li {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        color: #7d8ca7;
        cursor: pointer;
        padding: 0.6rem 0.2rem;
        text-transform: capitalize;

        &.active {
          color: $primary-color;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0.2rem;
            background: $primary-color;
          }
        }
      }
    }
  }
}

.tablereact {
  border-radius: 1rem;
}
.setting {
  .h4 {
    font-size: 2rem;
    font-weight: 600;
  }
  &Box {
    background: #fff;
    border-radius: 1rem;
    .h5 {
      font-size: 1.2rem;
    }

    &_input {
      width: 15rem;
      display: block;
      background: #e8effc;
      border-radius: 0.2rem;
      padding: 0.7rem 2rem;
    }

    &_ {
      gap: 10rem;

      .h3 {
        font-size: 15px;
        font-weight: 600;
      }
      p {
        font-size: 12px;
        color: #212121;
      }
    }
  }
}

.AnalyticsPage {
  &_top {
    grid-template-columns: 3fr 7fr;

    .card {
      padding: 1.2rem;
      z-index: 1;
      &_top {
        &l {
          .h3 {
            font-weight: 600;
            font-size: 1.3rem;
            text-transform: capitalize;
          }
          .h4 {
            font-size: 1rem;
            font-weight: 400;
            text-align: left;

            color: #777980;
          }
        }
      }
      .chartt {
        width: 14rem;
        height: 9rem;
        display: flex;
        justify-content: center;
        margin: 2rem auto 0;

        &_ {
          &Box {
            position: absolute;
            top: 53%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            .h5 {
              font-size: 1.6rem;
              font-weight: 600;
            }
            &- {
             
              width: fit-content;
              margin: 0 auto;
              padding: 0.2rem 0.8rem;
              border-radius: 0.2rem;
              color: #ed2656;
              font-size: 0.9rem;
              font-weight: 600;
            }
          }
        }

    
      }
      &_footer {
        position: relative;
        z-index: 9;
        &Box {
          .h4 {
            font-size: .9rem;
            font-weight: 400;
            color: #777980;
            margin-left: -1rem;
          }
          .h6 {
            font-size: 1rem;
            font-weight: 600;
            svg {
              color: #ed2656;
            }
          }
        }
      }
    }
  }
}

.cardRevenue {
  &_top {
    .h2 {
font-size: 1.2rem;
font-weight: 700;

    }
    .h4 {
      font-size: 1rem;
      font-weight: 400;
      color: #777980;
    }
    &r {
      ul {
        padding: .4rem;
        border-radius: .3rem;
        border: .1rem solid #ddd;

        li {
          border: 1px solid transparent;
          border-radius: 0.3rem;
          padding: 0.3rem 0.6rem;
          cursor: pointer;
          transition: .3s;
          text-transform: capitalize;
          
          &.active {
            border: 1px solid #E0E2E7;
            background: #F4ECFB;
            color: $primary-color;
            font-weight: 600;           
          }
        }
      }

    }
  }
}

.vendertotal {
  background: #E4EAF8;
  border-radius: .3rem;
  padding: .3rem 1rem;
font-size: 1.1rem;
font-weight: 700;
span {
  color: $primary-color;
}
}




.apexcharts-legend {
display: none !important;
}


.Discussions_right-center {

}

.Discussions_right-center::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 1rem 0px 1rem 0px;
}

.Discussions_right-center::-webkit-scrollbar {
  width: 0.4rem;
  background-color: #ddd;
}

@import "./header";
@import "./report";
@import "./innerPage";
@import "./footer";
@import "./responsive";
