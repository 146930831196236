@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --par: #f85032;
}
 /* .ant-menu-item-selected a {
    color: var(--par) !important;
} */
.ant-menu-item-selected {
    background-color: #ed2656  !important;
}

.ant-menu {
    color: #fff !important;
    background: #000000 !important;
}

.ant-menu-item,.ant-menu-item, .ant-menu-submenu-title, .ant-menu-submenu-title {
    color: #fff !important;
}

.ant-menu-submenu-title:hover,.ant-menu-submenu-title:hover {
    color: #fff;
}


 .ant-pagination-item-active a {
    color: #ed2656 !important;
}

 .ant-pagination-item-active {
    border-color: #ed2656 !important;
}


.dark\:bg-neutral-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0) !important;
}
.dark\:after\:bg-neutral-400::after {
    background-color: rgb(237 38 86) !important;
}


.loaderr {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: grid;
    place-content: center;
    z-index: 9999;
}