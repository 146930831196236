@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");

/* ------------------Typography------------------ */

html {
  // font-size: 62.5%;
  // font-size: 0.625vw;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  color: $font-color;
  font-size: .9rem;
  overflow: hidden;
}
body::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 1rem 0px 1rem 0px;
}

body::-webkit-scrollbar {
  width: 0.6rem;
  background-color: $secondary-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
  margin: .5rem 0;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 3.2rem;
}

h5 {
  font-size: 1.8rem;
  font-weight: 500;
}

h6 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
  line-height: 1.3;
}

a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  color: $font-color;

  &:hover {
    text-decoration: none;
  }
}

img {
  vertical-align: middle;
}

.w-100 {
  width: 100%;
}
.forget {
  a {
    color: $primary-color;
  }
}


.dorpdownwidth {
  width: 16rem;
  ul {
    gap: 0;
    li {
      border-bottom: 1px solid #ddd;
      padding: 1rem 1.4rem;
      svg {
        font-size: 1.2rem;
      }
    }
  }
  button {
    padding: 1rem 1.4rem;
    svg {
      font-size: 1.2rem;
    }
  }
}



.ProfileImage {
  width: 12rem;
}


.pictureUpload {
  &__left {
    width: 3rem;
    height: 3.2rem;
    background: #fff;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 0.3rem;
    &1 {
      padding: 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.3rem;
        object-fit: cover;
      }
    }
  }
  &__right {
    line-height: 1.1;
  }
}

.mustrequried {
  font-size: .8rem;
  margin-top: .2rem;
}


.term {
 label {
  display: flex;
  align-items: center;
  gap: .5rem;
 }
}

.orderselect{
  padding: 0.5rem .4rem;
  outline: none;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
}

.tableaction {
  img {
    width: 1.3rem;
  }
}

.ant-upload {
  .pictureUpload__left {
    width: 100%;
    place-items: center;
    display: grid;
    height: 100%;
  }
}
.ant-btn-primary {
  background-color: $primary-color;
}
.ant-btn-primary:hover {
  background-color: $primary-color !important;
}

ul.dropdownTar {
  padding: 0;
  background: #fff;
  box-shadow: 0 0 10px #ddd;
  border-radius: 0.7rem;
}

ul.dropdownTar li {
  padding: 0.2rem 2rem;
  border-bottom: .1rem solid #ddd;
  cursor: pointer;
}

.form-control {
  border-radius: .2rem;
  border: 1px solid #ddd;
  padding: .4rem 1rem;
  width: 100%;
  text-transform: capitalize;
}

.orderselect {
  text-transform: capitalize;
}

li.ant-rate-star {
  margin-inline-end: 2.9px !important;
}

.shopcover {
  border: 1px solid #ddd;
  background: #fff;
  margin-top: 1.5rem;
  text-align: center;
  cursor: pointer;
  height: 15rem;
  border-radius: 2rem;

}

.shopcover label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.shopcover img {
  max-width: 100%;
}
.axcc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;

}
.dashboard {

  &__left {

    &Nav {
      position: absolute;
      color: #fff;
      background: #fff;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: grid;
      display: none;
      place-items: center;
      right: -2rem;
      top: 2rem;
      cursor: pointer;
  
      i,svg {
        color: #000;
        transition: .5s;
      }
  
      &.active {
        .dashboard__leftNav {
          transform: rotate(180deg);
        }
      }
    }
  }
}



.profileImagg {
  img {
    width: 4rem;
  }
}


.liorder {
  background: #f5f5f5;
  margin: 0.4rem;
  padding: 0.4rem .6rem;
  border-radius: .4rem;
}



.Discussions_list {
  .Discussions_listl {
    padding: 0.6rem 1rem;
    border-radius: 0.7rem;
    transition: .3s;
    &.active {
      background: #ddd;

    }
  }
}
.Discussions_listlll {
  height: 53px;
    width: 53px;
}


